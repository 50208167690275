import React from 'react'
import styled from 'styled-components'


const QuoteForm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px 40px;
    background-color: #CB8C00;
    img{
        margin-bottom: 0;
        width:200px;
    }
`
const QuoteBody = styled.div`
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    text-align: center;
    .navigation__link:link, .navigation__link:visited {
        display: inline-block;
        font-size: 9rem;
        letter-spacing: .3rem;
        font-weight: 300;
        font-family: 'ArmyRust';
        color: #403E3E;
        padding: 1rem 2rem;
        text-decoration: none;
        text-transform: uppercase;
        border:2px solid #403E3E;
        background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #403E3E 50%);
        background-size: 220%;
        -webkit-transition: all .4s;
        transition: all .4s; }

    .navigation__link:hover, .navigation__link:active {
        background-position: 100%;
        color: #CB8C00;
        -webkit-transform: translateX(1rem);
                transform: translateX(1rem); }
    h1{
        color: #efefef;
        font-size: 3rem;
    }
    p{
        margin-bottom: 0;
    }

    span{
        font-family: 'ArmyRust';
        font-size: 40px;
        color: #403E3E;
    }

`

const Quote = ()=> (
    <QuoteForm>
        <QuoteBody>
            <h1>Schedule a FREE consultation  From us</h1>
            <p><a href="mailto:daniel@gbcontracting.org" className="navigation__link" style={{fontSize:'1.5rem'}}>Get A Quote</a></p>
        </QuoteBody>
    </QuoteForm>
);
export default Quote;
