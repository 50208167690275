import React from 'react'
import styled from 'styled-components'

// import { Link } from 'gatsby';
// const ContactFormHeader = styled.div`
//     display: flex;
//     flex-direction: column;
//     padding: 80px 40px;
//     h2{
//         margin-bottom: 2rem;
//         justify-content: center;
//         align-content:center;
//         font-family: 'ArmyRust';
//         font-size: 40px;
//         color: #D9D8D8;
//         font-weight: 300;
//     }
//     p{
//         color: #C4BFBF;
//         font-size: 25px;
//     }
// `

const Form = styled.div`
    padding: 80px;
    display: flex;
    background-color: #403E3E;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title{
        width: 70vw;
        h2{
            margin-bottom: .6rem;
            justify-content: center;
            align-content:center;
            font-family: 'ArmyRust';
            font-size: 4rem;
            color: #D9D8D8;
            font-weight: 300;
        }
        .consultation{
            color: #CB8C00
        }
        p{
            color: #C4BFBF;
            font-size: 1.5rem;
        }margin-bottom: 20px;
    }
    .inner-msg{
        width: 70vw;
        margin-bottom: 20px;
        .message> textarea{
            width:100%;
            border:0;
            border-radius: 10px;
            outline:0;
            padding: 10px 20px;
            height:20vh;
        }
    }
    .btn{
        font-family: 'ArmyRust';
        font-size: 2.5rem;
        color: #CB8C00;
    }
    .btn:hover{
        color: #D9D8D8;
    }
`
const Input = styled.div`
    display: flex;
    width: 70vw;
    justify-content: space-between;
    margin-bottom: 20px;
    .input > input{
        width: 22.5vw;
        padding: 10px 20px;
        border:0;
        border-radius: 10px;
        outline: 0;
    }
`

const ContactForm =() => (
    <Form>
        <div className="title">
            <h2> Schedule a</h2>
            <h2 className="consultation"> Consultation </h2>
            <p>Contact us today by completing a contact form below and get a FREE ESTIMATE!</p>
        </div>
        <Input>
            <div className="input">
                <input type="text" placeholder="Name"></input>
            </div>
            
            <div className="input">
                <input type="email" placeholder="Email"></input>
            </div>
            <div className="input">
                <input type="phone" placeholder="Phone Number"></input>
            </div>
        </Input>
        <div className="inner-msg">
            <div className="message">
                <textarea placeholder="Brief Description . . ."></textarea>
            </div>
        </div>
        <div className="btn">
            Submit
        </div>
    </Form>
)

export default ContactForm;