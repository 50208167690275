import React from 'react'

import Layout from '../components/layout'
import Story from '../components/story'
import ContactForm from '../components/contactForm'
import Quote from '../components/quote'
import SEO from '../components/seo'

const About = () =>(
    <Layout>
        <SEO title="Our Story" keywords={[`gatsby5`, `application`, `react`]} />
        <Story/>
        <ContactForm/>
        <Quote/>
    </Layout>
);

export default About