import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql} from 'gatsby'

const StoryContainer = styled.div`
    padding: 100px 250px;
    h1{
        font-size: 4rem;
        color: #CB8C00;
    }
    .contracting{
        font-size: 4rem;
        color: #403E3E;
    }
`

const STORY_QUERIES = graphql `
query {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___title]}, 
        filter: {fileAbsolutePath: {regex: "/(about)/"}}) {
        edges {
            node {
                excerpt(pruneLength: 1000)
                id
                frontmatter {
                title
                }
            }
            }
        }
}

`
const Story = () => (
    <StaticQuery
    query={STORY_QUERIES}
    render = {({allMarkdownRemark}) => (
        <StoryContainer>
            
                <div>
                    <div>
                        <h1>The Story of </h1>
                        <h1 className="contracting">GB Contracting</h1>
                    </div>
                    { allMarkdownRemark.edges.map(edge=> (
                        <div>
                            <h2>{edge.node.frontmatter.title}</h2>
                            <p>{edge.node.excerpt}</p>
                        </div>
                    ))
                    }
                </div>
        </StoryContainer>
    )}
    ></StaticQuery>
    
)



export default Story;

